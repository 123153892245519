export const environment = {
  production: true
};
export const CLIENT_ID = '5462g5p29hnkl2mlqmmir39frk';
export const USER_POOL_ID = 'ap-southeast-1_LFxjMJIYm';
export const ENDPOINT_PREFIX = 'https://bqb26zp9y2.execute-api.eu-west-1.amazonaws.com/v2';
export const URL_STATUS_PAGE = 'https://hconnect.statuspage.io/';
export const UI_ENV = null;
export const PRIVACY_URL = 'https://www.iubenda.com/privacy-policy/53654941';
export const TERMS_URL = 'https://www.iubenda.com/terms-and-conditions/53654941';
export const IMAGE_URL = 'https://images.hconnect.io/';
export const GRAPHQL_URL = 'https://bys3l2d4enerjnkklkd5mzw5ry.appsync-api.ap-southeast-2.amazonaws.com/graphql';
export const VENDER_APPLICATION_LOGO = 'https://c7bte72tjf.execute-api.ap-southeast-2.amazonaws.com/v1/vendor/applications/logo'; //v99 done
export const WEB_URL = 'https://hc-uat-portal.hconnect.io';
